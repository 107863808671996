
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    text: String,
    loadingText: String,
    disabled: Boolean,
    loading: Boolean,
  }
})
export default class BaseButton extends Vue {
  text!: string;
  loadingText!: string;
  disabled!: boolean;
  loading!: boolean;
}
