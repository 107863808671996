
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    placeholder: String,
    modelValue: String,
    disabled: Boolean,
    type: {
      type: String,
      default: 'text'
    },
  },
  data() {
    return {
      isTextVisible: false,
      isFocused: false
    }
  },
  methods: {
    toggleVisibility() {
      this.isTextVisible = !this.isTextVisible;
      if (this.isTextVisible) this.$refs.input.type = 'text';
      else this.$refs.input.type = 'password';
    },
  },
  computed: {
    getClass() {
      let classes = ['base-input'];
      if (this.disabled) classes.push('disabled');
      if (this.isFocused) classes.push('focused');
      return classes;
    }
  }
})
export default class BaseInput extends Vue {
  placeholder!: string;
  modelValue!: string;
  type!: string;
}
