
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    error: String,
    success: Boolean
  },
  computed: {
    getClass() {
      if (this.error) return 'error'
      else if (this.success) return 'success';
    }
  }
})
export default class BaseInput extends Vue {
  error!: string;
  success!: boolean;
}
