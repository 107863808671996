<template>
  <a v-bind="$attrs" class="base-button" :class="disabled ? 'disabled' : ''">
    <div v-if="!loading">{{ text }}</div>
    <template v-else>
      <div> {{ loadingText }} </div>
      <div class="sk-chase" style="margin-left:24px;margin-right:-32px;">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
    </template>
  </a>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    text: String,
    loadingText: String,
    disabled: Boolean,
    loading: Boolean,
  }
})
export default class BaseButton extends Vue {
  text!: string;
  loadingText!: string;
  disabled!: boolean;
  loading!: boolean;
}
</script>

<style lang="scss">
@import '@/design';
@import '@/design/spinkit.scss';
.base-button {
  display: flex;
  flex: 1;
  height: 56px;
  background-color: $green-100;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: $base-radius;
  color: white;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
  text-decoration: none !important;
  transition: 0.3s ease;
  padding: 16px;
}
.base-button:hover {
  background-color: lighten($green-100, 2%);
  color: white;
}
.base-button.disabled {
  background-color: $gray-200;
  border: 1px solid $gray-300;
  color: $gray-500;
  cursor: not-allowed;
}
.base-button.secondary {
  background-color: white;
  border: 1px solid $gray-400;
  color: $black;
}
.base-button.secondary:hover {
  color: $black;
  background-color: $gray-200;
}
</style>
