<template>
  <div class="login-form">
    <form @submit.prevent>
      <fieldset class="login-form__fields">
        <BaseField :success="success" :error="formError">
          <BaseInput
            :disabled="loading || success"
            :model-value="formFields.email"
            @update:model-value="formFields.email = $event"
            name="Email address"
            autocomplete="email"
            aria-autocomplete="email"
            placeholder="Email address">
            <img class="login-form__input--success-icon" v-if="success" src="@/assets/circular-green-tick.svg" alt="success tick">
          </BaseInput>
        </BaseField>
        <BaseField :success="success" :error="formError">
          <BaseInput
            :disabled="loading || success"
            :model-value="formFields.password"
            @update:model-value="formFields.password = $event"
            name="Password"
            autocomplete="password"
            aria-autocomplete="password"            
            type="password"
            placeholder="Password">
            <img class="login-form__input--success-icon" v-if="success" src="@/assets/circular-green-tick.svg" alt="success tick">
          </BaseInput>
        </BaseField>
      </fieldset>
    </form>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import client from '@/client';

@Options({
  components: { BaseInput, BaseField },
  props: {
    loading: Boolean,
    success: Boolean,
  },
  data() {
    return {
      formFields: {
        email: '',
        password: '',
      },
      formError: '',
    }
  },
  watch: {
    'formFields.email': function(val) {
      if (val) {
        this.formError = '';
      }
    },
    'formFields.password': function(val) {
      if (val) {
        this.formError = '';
      }
    },
  },
  methods: {
    handleSubmit(name = null) {
      let firstName = '';
      let lastName = '';

      if (name !== null) {
        //@ts-ignore
        const fullName = name.split(' ');
        firstName = fullName[0];
        lastName = fullName[fullName.length - 1];
      }
      
      return client
      .login(this.formFields.email, this.formFields.password, firstName, lastName)
      .then(response => {
        const user = {
          email: response.data.user.email,
          token: response.data.token,
          stripe_id: response.data.user.stripe_id
        };
        this.$store.commit('loginUser', user);
        return user;
      })
      .catch(error => {
        console.log(error.response.data.detail)
        if (error.response === undefined) {
          console.error(error);
          this.formError = 'Something went wrong! Please try again.'
        } else if (error.response.data) {
          this.formError = error.response.data.detail;
        }
        throw error;
      })
    },
  }
})
export default class RegisterForm extends Vue {}
</script>

<style lang="scss">
.login-form__fields {
  border: unset;
  display: flex;
  gap: 16px;
  padding: unset;
  margin: unset;
  flex-wrap: wrap;
}
.login-form__fields .base-field, 
.login-form__fields .base-input {
  flex: 1;
}
.login-form__input--success-icon {
  align-self: center;
  width: 16px;
  height: 16px;
}
</style>
