<template>
  <div :class="getClass" class="base-field">
    <slot></slot>
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    error: String,
    success: Boolean
  },
  computed: {
    getClass() {
      if (this.error) return 'error'
      else if (this.success) return 'success';
    }
  }
})
export default class BaseInput extends Vue {
  error!: string;
  success!: boolean;
}
</script>

<style lang="scss">
.base-field {
  display: block;
}
.base-field .error {
  margin-top: 10px;
  margin-bottom: 0;
  color: #BD1212;
}
.base-field.error > div {
  border-color: #BD1212;
}
.base-field.success > div {
  border-color: #64b60e;
}
</style>