
import { Options, Vue } from 'vue-class-component';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import client from '@/client';

@Options({
  components: { BaseInput, BaseField },
  props: {
    loading: Boolean,
    success: Boolean,
  },
  data() {
    return {
      formFields: {
        email: '',
        password: '',
      },
      formError: '',
    }
  },
  watch: {
    'formFields.email': function(val) {
      if (val) {
        this.formError = '';
      }
    },
    'formFields.password': function(val) {
      if (val) {
        this.formError = '';
      }
    },
  },
  methods: {
    handleSubmit(name = null) {
      let firstName = '';
      let lastName = '';

      if (name !== null) {
        //@ts-ignore
        const fullName = name.split(' ');
        firstName = fullName[0];
        lastName = fullName[fullName.length - 1];
      }
      
      return client
      .login(this.formFields.email, this.formFields.password, firstName, lastName)
      .then(response => {
        const user = {
          email: response.data.user.email,
          token: response.data.token,
          stripe_id: response.data.user.stripe_id
        };
        this.$store.commit('loginUser', user);
        return user;
      })
      .catch(error => {
        console.log(error.response.data.detail)
        if (error.response === undefined) {
          console.error(error);
          this.formError = 'Something went wrong! Please try again.'
        } else if (error.response.data) {
          this.formError = error.response.data.detail;
        }
        throw error;
      })
    },
  }
})
export default class RegisterForm extends Vue {}
