<template>
  <div :class="getClass">
    <input
      ref="input"
      v-bind="$attrs"
      :disabled="disabled"
      :value="modelValue"
      :type="type"
      @focus.passive="isFocused = true"
      @blur.passive="isFocused = false"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder">
    <slot v-if="$slots.default"></slot>
    <div class="base-input__visibility" v-if="type === 'password'" @click="toggleVisibility">
      <img v-if="isTextVisible" src="@/assets/hide.svg" />
      <img v-else src="@/assets/reveal.svg" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    placeholder: String,
    modelValue: String,
    disabled: Boolean,
    type: {
      type: String,
      default: 'text'
    },
  },
  data() {
    return {
      isTextVisible: false,
      isFocused: false
    }
  },
  methods: {
    toggleVisibility() {
      this.isTextVisible = !this.isTextVisible;
      if (this.isTextVisible) this.$refs.input.type = 'text';
      else this.$refs.input.type = 'password';
    },
  },
  computed: {
    getClass() {
      let classes = ['base-input'];
      if (this.disabled) classes.push('disabled');
      if (this.isFocused) classes.push('focused');
      return classes;
    }
  }
})
export default class BaseInput extends Vue {
  placeholder!: string;
  modelValue!: string;
  type!: string;
}
</script>

<style lang="scss">
@import '@/design';

.base-input {
  display: flex;
  flex: 1;
  height: 56px;
  min-width: 200px;
  background: white;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  border-radius: $base-radius;
  overflow: hidden;
  padding: 0 12px;
}
.base-input.disabled,
.base-input input:disabled {
  background: $gray-100;
}
.base-input.focused {
  border-color: $green-100;
}
.base-input input {
  flex: 1;
  outline: 0;
  border: 0;
  font-size: 16px;
  font-family: inherit;
}
.base-input input::placeholder {
  color: $gray-500;
}
.base-input__visibility {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 12px;
  &:hover {
    filter: brightness(1.1);
  }
}
</style>
